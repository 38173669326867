/*$bg-color: #125634;*/
// $bg-color: #008044;
$bg-color: #05b;
$bg-color-light: #05b;
$bg-color-dark: #05b;
$bg-color-darker: #05b;
// $bg-color-light: #22a266;
// $bg-color-dark: #006011;
// $bg-color-darker: #004000;
// $footer-color: rgba(0, 87, 49, 0.85);
$footer-color: rgba(0, 85, 187, 0.78);
$header-color: #eee;
$border-domain: #894;

$cred-col-1: #aa1;
$cred-col-2: #cc5;
