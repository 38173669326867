@import 'colors';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #000;
}

body {
	font-family: 'PT Serif', serif;

	header {
		height: 181px;

		@media screen and (max-width: 1000px) {
			section.main-header {
				margin: 0;
			}
		}

		@media screen and (max-width: 777px) {
			height: 125px;
		}

		@media screen and (max-width: 425px) {
			height: 101px;
		}

		.mode-off {
			display: none !important;
		}

		.header-wrapper {
			padding: 20px;
			padding-bottom: 0;
			display: flex;
			justify-content: center;
			/*width: 80vw;*/
			// border-bottom: 1px solid #333;
			box-shadow: 0 0 8px 2px #aaa;
			z-index: 10;
			position: relative;

			@media screen and (max-width: 777px) {
				justify-content: space-between;
			}

			@media screen and (max-width: 330px) {
				padding-left: 3px;
				padding-right: 3px;
			}

			.menu {
				position: relative;
				top: 25px;
				// right: 10px;
				display: none;
				height: 29px;

				span {
					display: block;
					background: $bg-color-light;
					width: 40px;
					height: 3px;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				&:hover {
					cursor: pointer;
				}

				@media screen and (max-width: 777px) {
					display: block;
				}
			}

			section.logo-header {
				img.logo {
					// width: 150px;
					height: 150px;

					&.owl:hover {
						animation: rotate 1.5s;
					}

					&:not(.owl):hover {
						cursor: default;
					}

					&.owl {
						@media screen and (max-width: 777px) {
							height: 100px;
						}

						@media screen and (max-width: 425px) {
							display: none;
						}
					}
				}

				&.awp {
					@media screen and (max-width: 965px) {
						display: none;
					}
				}
			}

			section.main-header {
				margin: 0 30px;

				@media screen and (max-width: 1000px) {
					margin: 0;
				}

				@media screen and (max-width: 777px) {
					display: flex;
				}

				.header-content {
					display: flex;
					/*justify-content: space-between;*/

					.column-wrapper {
						display: flex;
						width: 50%;
						min-width: 340px;
						flex-direction: column;
						justify-content: space-evenly;
						padding: 0px 20px;

						@media screen and (max-width: 777px) {
							display: block;
							width: 100%;
							height: 81px;
							text-align: center;
							padding-top: 10px;
							margin: 0 auto;

							&:nth-child(2) {
								display: none;
							}
						}

						@media screen and (max-width: 500px) {
							min-width: 250px;
							padding-left: 0;
							padding-right: 0;
						}

						@media screen and (max-width: 300px) {
							min-width: 220px;
						}

						&:nth-child(2) {
							min-width: 280px;

							@media screen and (max-width: 1050px) {
								padding: 0;
							}

							@media screen and (max-width: 777px) {
								display: none;
							}
						}
					}

					.header-title {
						font-size: 20px;

						@media screen and (max-width: 500px) {
							font-size: 16px;
						}

						@media screen and (max-width: 300px) {
							font-size: 14px;
						}

						p:first-child {
							font-size: 26px;
							color: $bg-color;

							@media screen and (max-width: 500px) {
								font-size: 22px;
							}

							@media screen and (max-width: 300px) {
								font-size: 20px;
							}
						}

						span {
							color: #c00;
							font-weight: bold;
							font-family: 'Play', sans-serif;
						}
					}

					.header-contact {
						div {
							/*margin-bottom: 4px;*/
						}

						a {
							color: #241e1e;
							text-decoration: none;
							font-size: 15px;
							line-height: 27px;
						}

						a:hover {
							color: #6b116b;
						}

						a::before {
							/*background-size: 100%;*/
							background-repeat: no-repeat;
							display: inline-block;
							width: 17px;
							height: 17px;
							content: "";
							margin-right: 15px;
							/*padding-bottom: 4px;*/
						}

						.phone a::before {
							background-image: url("../img/icons/phone.svg");
						}

						.mail a::before {
							background-image: url("../img/icons/mail.svg");
							/*background-size: 110%;*/
							position: relative;
							top: 3px;
						}

						.address a {
							cursor: default;
							color: #c00;
						}
					}
				}

				nav {
					width: 100%;

					.nav-container {
						display: flex;
						justify-content: center;
						// max-width: 800px;
						margin-left: auto;
						margin-right: auto;
						margin-top: 20px;
						font-size: 18px;
						// font-weight: bold;

						@media screen and (max-width: 777px) {
							position: fixed;
							top: 0;
							left: 0;
							padding: 0;
							margin: 0;
							width: 100vw;
							flex-direction: column;
							background-color: #fff;
							border-bottom: 1px solid $bg-color;
							display: none;
						}

						.x {
							display: none;
							background-color: #fff;
							background-image: url("../img/eks.svg");
							background-repeat: no-repeat;
							width: 25px;
							height: 25px;
							top: 10px;
							right: 10px;
							position: fixed;
							z-index: 100;
							// border: 1px solid $bg-color;
							border-width: 0;
							outline: 0;
							cursor: pointer;
							transition: background-color 0.4s;
						}

						.nav-element:first-child:hover ~ .x {
							background-color: #f7f8cf;
						}

						.nav-element {
							/*margin: 0 5px;*/
							/*padding: 5px;*/
							/*width: 150px;*/
							/*width: 145px;*/
							height: 60px;
							/*border: 1px black solid;*/
							border-top: 2px $bg-color solid;
							border-bottom: 2px #fff solid;
							// border-radius: 2px;
							text-align: center;
							// transform: skew(-35deg);
							// text-transform: uppercase;
							transition: all 0.4s;
							color: $bg-color;

							&.main-page {
								display: none;
							}

							@media screen and (max-width: 777px) {
								// border-width: 0;
								border-top-width: 1px;
								border-bottom-width: 0px;

								&.main-page {
									display: block;
									border-top: 0;
								}
							}

							a {
								display: block;
								/*width: 100%;*/
								height: 100%;
								padding: 5px 25px;
								/*background-color: #cca0a0;
								color: #3ead11;*/
								text-decoration: none;
								/*transform: skew(30deg);*/
								transition: all 0.25s;
								display: flex;
								justify-content: center;
								flex-direction: column;
								letter-spacing: 1px;
								color: $bg-color;

								span {
									// transform: skew(35deg);
									display: block;
									/*height: 100%;*/
								}
							}

							a:hover {
								/*filter: grayscale(80%);*/
								// background-color: $bg-color;
								// background: linear-gradient(to right, $bg-color-light, $bg-color);
								// color: #fff;
								// background-color: #f7f8cf;
							}

							&:hover, &.checked {
								// border-top-color: #fafffa;
								background-color: #f7f8cf;
								border-bottom-color: $bg-color;
								box-shadow: 0 0 12px 2px #ccc;
							}

							&.checked {
								// box-shadow: none;
								// text-decoration: underline;
								background-color: #f7f8cf;
								filter: sepia(0.1);
							}
						}
					}
				}
			}

			&.sticky {
				position: fixed;
				top: 0;
				padding: 0;
				width: 100vw;
				// height: 30px;
				background: #fff;

				.header-content {
					display: none !important;
				}

				.logo-header:nth-of-type(3) {
					display: none !important;
				}

				.owl {
					margin-top: 5px;
					height: 30px !important;
					width: 30px !important;
				}

				.nav-container {
					margin: 0 !important;

					.nav-element {
						height: 40px !important;
						// border-top-color: #fff !important;
					}
				}

				.menu {
					top: 5px;

					span {
						margin-bottom: 5px;
					}
				}
			}
		}

		.call-us {
			position: fixed;
			bottom: 25px;
			left: 25px;
			width: 50px;
			height: 50px;
			border-radius: 25px;
			padding: 5px;
			overflow: hidden;
			z-index: 100;

			@media screen and (max-width: 450px) {
				bottom: 10px;
				left: 10px;
			}

			div {
				background: #ffffff;
				width: 40px;
				height: 40px;
				border-radius: 20px;
			}

			a {
				display: block;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 50px;
					height: 50px;
				}
			}
		}
	}

	main {
		// background-color: $bg-color;
		overflow: hidden;
		min-height: calc(100vh - 357px);

		@media screen and (max-width: 777px) {
			min-height: calc(100vh - 301px);
		}

		@media screen and (max-width: 425px) {
			min-height: calc(100vh - 277px);
		}

		h1 {
			// text-decoration: underline;
			padding-left: 10%;
			margin: 30px auto;
			font-weight: normal;
			// text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 28px;
			color: $bg-color-darker;

			@media screen and (max-width: 600px) {
				font-size: 24px;
			}

			@media screen and (max-width: 450px) {
				font-size: 20px;
				padding-left: 5%;
			}
		}

		hr.section-hr {
			position: relative;
			top: -20px;
			// width: 80%;
			margin-left: 8%;
			height: 2px;
			// color: $bg-color;
			background: linear-gradient(to left, #fff, $bg-color-darker);
			border: none;

			@media screen and (max-width: 450px) {
				margin-left: 4%;
			}
		}

		section.main {
			height: calc(100vh - 181px);
			background-image: url("../img/gallery/IMG_6491.jpg");
			background-size: cover;
			background-position: center 0%;
			// animation: closer 5s;
			// transform: scale(1.05);

			@media screen and (max-width: 777px) {
				height: calc(100vh - 125px);
			}

			@media screen and (max-width: 425px) {
				height: calc(100vh - 101px);
			}

			.main-content {
				position: absolute;
				// background-color: $bg-color;
				background: rgba(233, 233, 233, 0.85);
				// background: rgba(255, 221, 153, 0.7);
				// opacity: 20%;
				width: 500px;
				height: 200px;
				bottom: 40px;
				right: 40px;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				font-size: 20px;
				font-weight: bold;
				// font-family: 'Lato', sans-serif;
				color: #b2a552;//b28e52
				// color: rgba(236, 184, 12, 0.9);
				box-shadow: 0 0 2px 1px #bbb;
				text-shadow: 0 0 1px #ddd;

				@media screen and (max-width: 900px) {
					width: 350px;
					height: 180px;
					font-size: 18px;
				}

				@media screen and (max-width: 450px) {
					width: 80%;
					right: 10%;
					height: 150px;
					font-size: 15px;

				}

				p:first-child, p:last-child {
					line-height: 266.67%;

					a {
						color: #b2a552;

						&:hover {
							color: #908330;
						}
					}
				}

				p:nth-of-type(2) {
					span {
						@media screen and (max-width: 350px) {
							display: none;
						}
					}
				}

				hr {
					width: 80%;
					position: relative;
					height: 1px;
					left: 5%;
					background-color: #991;
					border: none;
				}

				hr:last-child {
					margin-top: 5px;
					left: 15%;
				}

				// .calle {
				// 	position: absolute;
				// 	top: -4px;
				// 	left: -20px;
				// 	padding: 4px;
				// 	background: #ee0;
				// 	border: 3px red solid;
				// 	transform: rotateZ(-20deg);
				// 	font-size: 14px;

				// 	a {
				// 		color: #a00;
				// 	}

				// 	@media screen and (max-width: 900px) {
				// 		font-size: 12px;
				// 		top: 0px;
				// 		left: -25px;
				// 	}

				// 	@media screen and (max-width: 450px) {
				// 		font-size: 12px;
				// 		top: -20px;
				// 		left: -30px;
				// 	}
				// }
			}
		}

		section.title {
			height: 150px;
			width: 100vw;
			font-size: 30px;
			text-decoration: underline;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
		}

		section.domains {

			a.more {
				margin: 0 auto;
				margin-left: auto;
				margin-right: auto;
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				width: 175px;
				height: 30px;
				background: $footer-color;
				color: #ddd;
				display: none;

			}

			p {
				margin: 20px 40px 40px 40px;
				text-align: justify;
				width: 500px;
				display: none;
			}

			article {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				flex-wrap: wrap;
				background-image: url("../img/lady-justice-2388500_1920.jpg");
				background-size: cover;
				// background-position: y20%;
				filter: sepia(1);
				/*height: 600px;*/
				overflow: hidden;

				a {
					cursor: default;
					// width: 33.33%;
					width: 50%;
					text-align: center;
					// border: 1px black dotted;
					// background-color: $bg-color;
					color: blue;
					font-size: 20px;
					font-weight: normal;
					background-color: rgba(236, 184, 12, 0.9);
					transition: all 0.5s;
					display: block;

					&:hover {
						box-shadow: 0 0 20px 4px #672;
						position: relative;
						z-index: 5;
						// cursor: pointer;
					}

					&:nth-child(1), &:nth-child(2) {
						border-bottom: 1px solid $border-domain;
					}

					&:nth-child(2), &:nth-child(4) {
						border-left: 1px solid $border-domain;
						// border-right: 1px solid $border-domain;
					}

					h3 {
						// font-family: 'Roboto Slab', sans-serif;
						font-weight: normal;
						// line-height: 125px;
						margin: 60px auto;
						height: 62px;
					}

					.domain-logo {
						// background-image: url("../img/icons/auction.svg") no-repeat center center;
						width: 50px;
						height: 50px;
						margin: 35px auto;
						// position: absolute;
					}

					&:nth-child(3n+1) .domain-logo {
						background-image: url("../img/icons/auction.svg");
					}

					&:nth-child(3n+2) .domain-logo {
						background-image: url("../img/icons/courthouse.svg");
					}

					&:nth-child(3n) .domain-logo {
						background-image: url("../img/icons/balance.svg");
					}

					@media screen and (max-width: 650px) {
						width: 50%;

						&:nth-child(1), &:nth-child(3) {
							border-right: 1px solid $border-domain;
						}

						&:nth-child(2) {
							border-left-width: 0;
							border-right-width: 0;
						}

						&:nth-child(4) {
							border-bottom: 1px solid $border-domain;
						}

						&:nth-child(5) {
							border-left-width: 0;
						}
					}

					@media screen and (max-width: 450px) {
						width: 100%;
						border-bottom: 1px solid $border-domain;
						border-left-width: 0 !important;
						border-right-width: 0 !important;
						font-size: 16px;

						h3 {
							margin: 20px auto;
						}

						.domain-logo {
							margin: 20px auto;
						}
					}
				}
			}
		}

		section.blog {
			margin-bottom: 50px;

			article {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-evenly;
				padding: 0 30px;

				.news {
					width: 400px;
					max-width: 800px;
					margin: 0 20px;
					margin-bottom: 40px;
					// height: 200px;
					// background: yellow;
					text-align: center;

					&.get {
						width: auto;
					}

					.image-wrapper {
						width: 400px;
						max-width: 800px;
						height: 250px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						margin-left: auto;
						margin-right: auto;

						&.one {
							width: 100%;
						}
					}

					img {
						// // width: 100%;
						// max-width: 400px;
						// max-height: 300px;
					}

					h4 {
						font-weight: normal;
						// color: #991;
						color: #b28e52;
						margin: 20px 0;
						font-size: 12px;
						text-align: left;
					}

					h3 {
						// font-family: 'Roboto Slab', sans-serif;
						// font-weight: normal;
						// line-height: 125px;
						margin: 15px auto;
						text-align: center;
						color: $bg-color-dark;
						// height:
					}

					p {
						text-align: justify;
						margin-bottom: 20px;
						font-weight: normal;
						// height: 100px;
						// overflow: hidden;
						// // white-space: nowrap;
					}

					a {
						color: #b28e52;
						text-decoration: underline;
						margin-left: auto;
						margin-right: auto;

						&.return {
							text-align: left !important;
							margin-left: 0 !important;
						}
					}
				}

				// @media screen and (max-width: 940px) {
				// 	// width: 50%;
				// 	flex-wrap: wrap;
				// 	display: block;
				// }

				@media screen and (max-width: 1400px) {
					.news.main-news {
						width: 350px;

						.image-wrapper {
							width: 100%;
						}
					}
				}

				@media screen and (max-width: 1250px) {
					.news.main-news {
						width: 300px;

						.image-wrapper {
							width: 100%;
						}
					}
				}

				@media screen and (max-width: 1080px) {
					.news.main-news {
						width: auto;
						max-width: 100% !important;

						h4 {
							text-align: center;
						}
					}
				}

				@media screen and (max-width: 940px) {
					// width: 50%;
					flex-wrap: wrap;
					// display: block;

					.news {
						width: 40%;
						// max-width: 400px;
						margin: 0;
						margin-bottom: 40px;
						// border-bottom: 1px solid #000;

						.image-wrapper {
							width: 100%;
							// max-width: 300px;
						}

						h4 {
							text-align: center;
							margin: 5px;
						}

						p {
							margin-bottom: 5px;
						}
					}
				}

				@media screen and (max-width: 750px) {
					display: block;

					.news {
						width: auto;
					}
				}
			}
		}
	}

	footer {
		// min-height: 150px;
		// background: rgb(90, 131, 93);

		article {
			display: flex;
			flex-flow: row-reverse;

			@media screen and (max-width: 700px) {
				display: block;
			}

			section {
				width: 50%;
				// height: 772px;

				@media screen and (max-width: 700px) {
					width: 100%;
				}


				&.map {
					@media screen and (max-width: 700px) {
						height: 500px;
					}
					iframe {
						width: 100%;
						// height: inherit;
						height: 100%;
						min-height: 500px;
					}
				}

				&.contact-wrapper {
					background-image: url("../img/hammer-802296_1920.jpg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					// filter: saturate(11) sepia(90%) opacity(25%);

					#contact {
						// height: 600px;
						background-color: $footer-color;
						padding: 15px;

						address {
							font-family: 'PT Serif', sans-serif;
							font-style: normal;
							// background-color: rgba(0, 128, 68, 0.85);
							// padding: 20px 80px;
							padding: 18px 0;

							p {
								// line-height: 20px;
								font-size: 18px;
								// color: #b8c9c7;
								color: #ddd;
								margin-bottom: 20px;
								text-align: center;

								&.lawyer {
									font-weight: bold;
									// letter-spacing: 1px;
									font-size: 22px;

									@media screen and (max-width: 400px) {
										font-size: 18px;
									}
								}

								&.opening {
									// display: none;

									span {
										color: rgba(255,100,100,0.9);
									}

									@media screen and (max-width: 400px) {
										font-size: 16px;
									}
								}
							}

							article {
								display: flex;
								justify-content: space-evenly;
								margin: 20px auto;

								@media screen and (max-width: 950px) {
									display: block;
								}

								div {

									@media screen and (max-width: 950px) {
										&:first-child {
											margin-bottom: 20px;
										}
									}

									p {
										text-align: left;
										margin-bottom: 7px;
										font-size: 15px;

										@media screen and (max-width: 950px) {
											text-align: center;
										}

										@media screen and (max-width: 400px) {
											font-size: 13px;
										}
									}

									a {
										// color: #241e1e;
										color: inherit;
										text-decoration: none;
										// font-size: 17px;
										// line-height: 27px;
									}

									a:hover {
										color: #fff;
									}

									a::before {
										/*background-size: 100%;*/
										background-repeat: no-repeat;
										display: inline-block;
										width: 17px;
										height: 17px;
										content: "";
										margin-right: 15px;
										/*padding-bottom: 4px;*/
									}

									p.phone a::before {
										background-image: url("../img/icons/phone_light.svg");
									}

									p.mail a::before {
										background-image: url("../img/icons/mail_light.svg");
										/*background-size: 110%;*/
										position: relative;
										top: 3px;
									}
								}
							}
						}

						form {
							margin: 0 auto;
							max-width: 450px;
							width: 80%;

							@media screen and (max-width: 1000px) {
								// width: 80%;
							}

							h4 {
								// line-height: 20px;
								font-size: 18px;
								// color: #b8c9c7;
								color: #ddd;
								margin-bottom: 5px;
								text-align: left;
							}

							hr {
								// position: relative;
								// top: -50px;
								// width: 80%;
								// margin-left: 8%;
								height: 1px;
								// color: $bg-color;
								background: linear-gradient(to right, #fff, $footer-color);
								border: none;
								// margin-bottom: 20px;
							}

							input[type=text] {
								width: 100%;
								height: 30px;
								padding: 15px;
								font-size: 14px;
								margin: 10px auto;
								font-family: 'Montserrat', sans-serif;
								background: transparent;
								border: 1px solid #ddd;
								border-radius: 2px;
								// color: #f19b9b;
								color: #eee;
								outline: none;

								&::-webkit-input-placeholder {
									color: #aaa;
								}
							}

							textarea {
								width: 100%;
								height: 200px;
								padding: 15px;
								margin: 10px auto;
								font-family: 'Montserrat', sans-serif;
								font-size: 14px;
								border: 1px solid #ddd;
								border-radius: 3px;
								resize: none;
								background: transparent;
								color: #eee;
								outline: none;

								&::-webkit-input-placeholder {
									color: #aaa;
								}
							}

							input[type=submit] {
								width: 100%;
								height: 30px;
								// border: 2px solid #ddd;
								border: 0;
								border-radius: 3px;
								font-family: 'Montserrat', sans-serif;
								font-size: 14px;
								margin: 10px auto;
								border: 1px solid #ddd;
								background: transparent;
								color: #ddd;

								&:hover {
									color: rgba(0, 87, 49, 0.85);
									font-weight: bold;
									background: #ddd;
									cursor: pointer;
								}
							}

							#info {
								color: #f55;
								text-align: center;
								font-size: 14px;
								margin-bottom: 10px;
							}

							@media screen and (max-width: 400px) {
								input[type=text], textarea {
									font-size: 13px;
									padding: 8px;
								}
							}
						}

						.rodo {
							display: block;
							text-align: center;
							width: 100%;
							padding-bottom: 5px;

							color: #bb1;
							font-size: 13px;
							// font-style: italic;
							font-family: 'Play', sans-serif;

							span {
								font-weight: bold;
							}

							&:hover {
								color: #cc2;
								cursor: pointer;
							}
						}
					}
				}
			}
		}

		$cred-col-1: #bb1;
		$cred-col-2: #dd4;

		#credits {
			// background: linear-gradient($footer-color, rgba(0, 38, 0, 0.95));
			// background: linear-gradient(#c3b662, #ed5, #c3b662);
			background: linear-gradient($cred-col-1, $cred-col-2, $cred-col-1);
			// background: #C8B94A;
			color: #640;
			// color: $footer-color;
			text-align: center;
			line-height: 150%;
			font-size: 12px;
			letter-spacing: 1px;
			font-family: 'Play', sans-serif;
			border: 1px solid #777;
			// filter: sepia(1);

			span {
				color: $bg-color-dark;
				font-weight: bold;

				&:hover {
					color: $bg-color-darker;
				}
			}
		}
	}

	.fb {
		position: fixed;
		top: 35vh;
		right: -340px;
		transition: right 0.5s;

		&:hover {
			right: 0;
		}

		.fb-img {
			width: 50px;
			position: absolute;
			top: 0;
			left: -50px;
		}

		@media screen and (max-width: 400px) {
			display: none;
		}
	}

	.christmas {
		position: fixed;
		display: flex;
		top: 0;
		left: 0;
		padding: 5vw;
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.4);

		div	{
			position: relative;

			p {
				position: absolute;
				width: 50%;
				top: 10%;
				left: 5%;
				color: white;
				font-size: 18px;
				font-weight: bold;
			}

			button {
				position: absolute;
				top: 0;
				right: 0;
				border: 1px solid #666;
				color: #666;
				background: transparent;
				padding: 5px;
				cursor: pointer;

				&:hover {
					border-color: #000;
					color: #000;
				}
			}
		}

		@media screen and (max-width: 800px) {

			div {
				img {
					width: 100%;
				}

				p {
					font-size: 15px;
				}
			}
		}

		@media screen and (max-width: 400px) {

			div p {
				font-size: 13px;
				width: 70%;
			}
		}
	}
}

@keyframes rotate {
	0% { transform: rotate(0deg); }
	8.33% { transform: rotate(5deg); }
	16.67% { transform: rotate(0deg); }
	25% { transform: rotate(-5deg); }
	33.33% { transform: rotate(0deg); }
	41.67% { transform: rotate(3deg); }
	50% { transform: rotate(0deg); }
	58.33% { transform: rotate(-3deg); }
	66.67% { transform: rotate(0deg); }
	75% { transform: rotate(1deg); }
	83.33% { transform: rotate(0deg); }
	91.67% { transform: rotate(-1deg); }
	100% { transform: rotate(0deg); }
}

@keyframes closer {
	from { transform: scale(1); }
	to { transform: scale(1.05); }
}

/* ### OFERTA */

.offer-page {
	// background: $bg-color;
	// opacity: 0.4;
	padding: 0;

	header {
		padding: 0 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 100%;
		height: 100%;

		@media screen and (max-width: 500px) {
			padding: 0 10px;
		}

		.offer-header {
			width: 100%;
			height: auto;
			// padding: 0 10px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;

			&:last-child {
				flex-direction: row-reverse;
			}

			@media screen and (max-width: 950px) {
				display: block;
			}

			img {
				height: 280px;
				width: 420px;
				display: block;

				&.toga {
					height: 420px;
					width: 280px;
				}

				@media screen and (max-width: 950px) {
					margin: 0 auto;
					margin-bottom: 30px;
				}

				@media screen and (max-width: 500px) {
					width: 90%;
					height: auto;
				}
			}

			article {
				width: calc(100% - 450px);
				display: block;
				// margin-left: 50px;

				&.toga-article {
					width: calc(100% - 310px);

					@media screen and (max-width: 950px) {
						width: 90%;
					}
				}

				@media screen and (max-width: 950px) {
					width: 90%;
					margin: 0 auto;
				}

				p {
					margin: 15px 0;
					text-align: justify;
					line-height: 24px;

					&:first-child {
						margin-top: 0;
					}

					&::first-letter {
						font-size: 20px;
						margin-left: 20px;
					}
				}
			}
		}
	}

	article.offers {
		background-image: url("../img/lady-justice-2388500_1920.jpg");
		filter: sepia(1);

		.offer {
			display: block;
			font-family: 'PT Serif', serif;
			background-color: rgba(236, 184, 12, 0.9);
			padding: 10px 40px;
			padding-left: 150px;
			background-size: 75px;
			background-repeat: no-repeat;
			background-position: 3% 65%;

			@media screen and (max-width: 650px) {
				padding: 10px 20px;
				background-image: none !important;
			}

			@media screen and (max-width: 450px) {
				font-size: 0.9em;
			}

			h2 {
				margin: 10px 20px;
				font-size: 30px;
				position: relative;
				left: -100px;
				width: 200%;

				@media screen and (max-width: 650px) {
					left: 0;
					width: auto;
					font-size: 24px;
				}
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				padding-left: 15px;
				width: 100%;
				border-bottom: 1px solid #000;
				padding-bottom: 10px;

				@media screen and (max-width: 650px) {
					border-bottom-width: 0;
				}

				li {
					margin: 5px 20px;
					width: 40%;

					@media screen and (max-width: 650px) {
						width: 100%;
					}
				}
			}

			&:last-child ul {
				border-bottom: none;
			}

			p {
				text-align: justify;

				&::first-letter {
					font-size: 20px;
					margin-left: 20px;
				}
			}

			&:nth-child(3n+1) {
				background-image: url("../img/icons/auction.svg");
			}

			&:nth-child(3n+2) {
				background-image: url("../img/icons/courthouse.svg");
			}

			&:nth-child(3n) {
				background-image: url("../img/icons/balance.svg");
			}
		}
	}
}

/* ### KANZLEI */

main.main-about-page {

	background-image: url("../img/clause-684509_1280.jpg");
	background-size: cover;
	background-position: center center;

	.about-page-wrapper {
		background-color: rgba(255,255,255,0.7);
		height: 100%;
		padding: 15px;

		h1 {
			// padding-left: 0 !important;
		}

		.about-page {
			font-family: 'Lato', sans-serf;

			header {
				display: flex;
				justify-content: center;
				// min-height: 600px;
				height: auto;

				.gallery {
					// height: 100%;
					width: 50%;
					overflow: hidden;
					// display: none;
					padding: 6px;
					// border: 3px solid $bg-color-dark;
					border-right-width: 0;

					@media screen and (max-width: 450px) {
						padding: 0;
						padding-bottom: 6px;
					}

					.gallery__wrapper {
						// max-width: 600px;
						height: 100%;
						max-height: 600px;

						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						// flex-direction: column;
						overflow: hidden;
						text-align: center;
						position: relative;

						img#gallery__image {
							// max-width: 600px;
							// max-height: 500px;
							height: 100%;
							width: auto;
							// height: 400px;
							// width: 100%;
							margin-left: auto;
							margin-right: auto;
							// height: auto;
							// width: auto;
							// min-width: 100%;
							// opacity: 0;

							// &.vertical {
							// 	height: auto;
							// 	width: 600px;
							// }

							// &.horizontal {
							// 	width: auto;
							// 	height: 600px;
							// }

							@media screen and (max-width: 750px) {
								height: auto;
								width: 100%;
							}
						}

						.gallery__image-loading {
							display: none;
						}
					}
				}

				.biography-wrapper {
					width: 50%;
					// height: 400px;
					// margin-left: 10px;
					// border: 3px solid #a81;
					// border: 3px solid $bg-color-dark;
					padding: 6px;
					display: block;
					cursor: default;
					background-image: url("../img/logoadw.png");
					background-size: 200px;
					background-repeat: no-repeat;
					background-position: center center;

					@media screen and (max-width: 450px) {
						padding: 0;
					}

					.biography {
						// width: calc(100vw - 600px);
						// background: rgb(161, 133, 6); //ca1
						// border: 6px solid #fff;
						// outline: 3px solid #a81;
						background-color: rgba(0, 128, 68, 0.8);
						// background-color: rgba(0, 120, 40, 0.8);
						padding: 20px;
						// font-style: italic;
						font-size: 15px;
						text-align: left;
						// margin: 0 10px;
						height: 100%;

						@media screen and (max-width: 450px) {
							padding: 8px;
							font-size: 14px;
						}

						h2 {
							margin: 5px auto;
							color: #efe;
							font-family: 'Roboto Slab', sans-serif;
							font-weight: normal;
						}

						div {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							// height: 100%;

							p {
								font-family: 'Lato', sans-serif;
								// color: rgb(9, 32, 9);
								color: #cdc;
								line-height: 150%;
								margin-top: 20px;
								text-align: justify;

								&::first-letter {
									font-size: 20px;
									margin-left: 20px;
								}
							}

						}

					}
				}

				@media screen and (max-width: 750px) {
					display: block;

					section.gallery, section.biography-wrapper {
						width: auto;
					}
				}
			}

			.about-content {
				// height: 100%;
				font-size: 16px;
				margin: 20px;

				@media screen and (max-width: 450px) {
					margin: 20px 0;
					font-size: 15px;
				}

				p {
					margin-top: 30px;
					width: 50%;
					display: block;
					padding-left: 15px;
					border-left: 20px solid #a80;
					position: relative;
					text-align: justify;

					a {
						color: #ff8900;
					}

					&:nth-child(1)::after {
						background-image: url("../img/icons/balance.svg");
						background-repeat: no-repeat;
						background-size: 80px;
						// background-position: -100px 0;
						position: absolute;
						left: calc(90vw - 100px);
						top: 0;
						display: block;
						width: 100px;
						height: 100px;
						content: '';
					}

					&:nth-child(2) {
						left: 25%;
					}

					&:nth-child(3) {
						left: 50%;

						&::before {
							background-image: url("../img/icons/courthouse.svg");
							background-repeat: no-repeat;
							background-size: 80px;
							// background-position: -100px 0;
							position: absolute;
							left: -100%;
							top: -20px;
							display: block;
							width: 100px;
							height: 100px;
							content: '';
						}
					}

					&:nth-child(4) {
						width: 100%;
					}

					&::first-letter {
						font-size: 20px;
						margin-left: 20px;
					}

					@media screen and (max-width: 750px) {
						width: auto;
						left: 0 !important;

						&::before, &::after {
							display: none !important;
						}
					}

					@media screen and (max-width: 350px) {
						padding-left: 0;
						border-left-width: 0;
						font-size: 15px;
					}
				}
			}
		}
	}
}

/* ### MEDIA */

main.main-media-page {

	background-image: url("../img/clause-684509_1280.jpg");
	background-size: cover;
	background-position: center center;
	// opacity: 0.7;
	padding: 0;

	.media-page-wrapper {
		background-color: rgba(255,255,255,0.7);
		padding: 1px;

	}
}

.media-page {
	// display: block;
	padding: 0 29px;
	max-width: 1500px;
	margin: 0 auto;

	@media screen and (max-width: 460px) {
		padding: 0 10px;
	}

	a {
		color: #ff8900;
		font-style: italic;
		// text-decoration: underline;

		&:hover {
			color: #ffab00;
		}
	}

	h2 {
		color: $bg-color-dark;
		font-size: 20px;
		margin-top: 50px;
		margin-bottom: 20px;
	}

	.tv {

		img {
			margin-top: 15px;
			max-width: 600px;
			width: 100%;
			border: 1px solid #aaa;
		}

		// iframe {
		// 	// width: 800px;
		// 	height: 536px;
		// 	border: none;
		// 	outline: 1px solid #aaa;
		// 	overflow: hidden;
		// 	background: rgba(233, 233, 233, 0.63);

		// 	@media screen and (max-width: 550px) {
		// 		width: 100% !important;
		// 		height: calc(450px + (100vw - 280px) * 0.31851) !important;
		// 	}
		// }
	}

	.school {
		iframe {
			max-width: 800px;
			height: 536px;
			border: none;
			outline: 1px solid #aaa;
			overflow: hidden;
			background: rgba(233, 233, 233, 0.63);

			@media screen and (max-width: 550px) {
				width: 100% !important;
				height: calc(450px + (100vw - 280px) * 0.31851) !important;
			}
		}
	}

	.newspaper {

	}

	.prize {
		position: relative;

		.eagle {
			transform: rotateZ(-15deg);
			width: 60px;
			position: absolute;
			top: 60px;
			left: 10%;
			z-index: 1;
		}

		img {
			max-width: 600px;
			width: 100%;
			border: 1px solid #aaa;
		}
	}

	.customers {
		// width: 1201px;
		p {
			margin-bottom: 10px;
		}

		article {
			display: block;
			max-width: 1200px;
			overflow: hidden;
			border-left: 1px solid #aaa;
			border-right: 1px solid #aaa;

			.opinion-wrapper {
				display: flex;
				left: -1px;
				width: 4000px;
				position: relative;
				transition: left 0.5s;

				.opinion {
					width: 400px;
					display: block;
					padding: 10px;
					border-top: 1px solid #aaa;
					border-left: 1px solid #aaa;
					background: rgba(244, 244, 244, 0.5);

					&:nth-child(odd) {
						background: rgba(224, 224, 224, 0.5);
					}

					@media screen and (max-width: 460px) {
						width: calc(100vw - 20px);
					}

					h3 {
						font-size: 13px;
						font-weight: normal;
						padding-left: 10px;
						margin-bottom: 10px;
						color: #ff8900;
						border-bottom: 1px solid #aaa;
					}

					p {
						font-size: 13px;
						font-family: 'Cardo', sans-serif;
						font-style: italic;

						&::first-letter {
							margin-left: 15px;
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}

.rodo-info {
	display: none;
	font-family: 'Lato', sans-serif;
	padding: 10px;
	position: fixed;
	top: 10%;
	left: 5%;
	width: 90vw;
	height: 80vh;
	background: rgba(200, 238, 200, 0.95);
	z-index: 10;
	overflow-y: scroll;

	@media screen and (max-width: 750px) {
		left: 5%;
		width: 90vw;
	}

	.rodo-border {
		border: 1px solid black;
		padding: 10px;
		margin-bottom: 10px;
	}

	h1 {
		font-size: 22px;
		margin: 10px;
	}

	h2 {
		font-size: 18px;
		margin: 10px;
	}

	p, li {
		font-size: 16px;
		font-weight: normal;
	}

	ol {
		margin-left: 30px !important;
	}

	.rodo-x {
		background-image: url("../img/eks.svg");
		background-repeat: no-repeat;
		width: 23px;
		height: 23px;
		top: 16px;
		right: 9px;
		position: absolute;
		z-index: 100;
		border-width: 0;
		cursor: pointer;
		transform: scale(1.5);
	}
}

/* ### NEWS */

#tags {
	margin-top: 10px;

	h3 {
		color: black;
		margin-bottom: 4px;
		font-weight: normal;
		font-size: 15px;
		text-align: left;
	}

	#tags-store {
		display: none;
	}

	.list-of-tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 8px;

		div {
			margin: 5px;
			padding: 2px 7px;
			padding-bottom: 4px;
			background: #008044;
			color: white;
			border-radius: 9px;
			display: inline;

			a {
				// display: block;
				text-decoration: none;
				color: unset;
			}
		}
	}
}

@import 'kontakt';