@import 'colors';
@import 'footer';

.call {
	// position: absolute;
	// top: 0;
	// left: -20px;
	padding: 4px;
	// background: #ee0;
	border: 3px #a00 solid;
	// transform: rotateZ(-20deg);
	font-size: 14px;
	width: 160px;
	margin: 20px auto;
	text-align: center;
	font-weight: bold;

	a {
		color: #a00;
	}

	@media screen and (max-width: 900px) {
		font-size: 12px;
		top: 0px;
		left: -25px;
	}

	@media screen and (max-width: 450px) {
		font-size: 12px;
		top: -20px;
		left: -30px;
	}
}

.contact-page__information {
	width: 80%;
	height: 100%;
	margin: 20px auto;
	display: flex;
	justify-content: space-between;

	.info {
		// width: 50%;
		margin: 10px;

		h2 {
			font-size: 18px;
			margin-bottom: 15px;
			text-align: center;
			color: $bg-color-dark;
		}

		p {
			text-align: justify;
			line-height: 200%;
		}

		&.info-box {
			margin-left: 40px;
		}

		&.fb-box {
			min-width: 340px;
			height: 539px;

			.fb-content {
				display: block;

				&.loading {
					// background-color: #234;
					// outline: 3px solid yellow;
					display: none;
				}
			}

			.loader {
				display: flex;
				padding-left: 45%;
				padding-top: 100px;

				hr {
					border: 0;
					margin: 0;
					width: 15px;
					height: 15px;
					position: absolute;
					border-radius: 50%;
					// background-color: #345;
					animation: spin 2s ease infinite;

					&:first-child {
						background: rgb(11, 204, 20);
						animation-delay: -1.5s;
					}

					&:nth-child(2) {
						background: rgb(223, 43, 40);
						animation-delay: -1s;
					}

					&:nth-child(3) {
						background: rgb(238, 205, 17);
						animation-delay: -0.5s;
					}

					&:last-child {
						background: rgb(40, 96, 168);
					}
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		width: 90%;
	}

	@media screen and (max-width: 750px) {
		display: block;

		.info p {
			line-height: 150%;
		}

		.info.info-box {
			margin: 0;
		}

		.info.fb-box {
			margin: 10px auto;
			display: none;
		}
	}
}

.contact-page__main {
	background-image: url("../img/justice-2060093_1920.jpg");
	// filter: sepia(0.5);
	filter: hue-rotate(45deg);
	// filter: grayscale(0.4);
	background-size: cover;
	background-position: center;
	// width: 80%;
	// margin: 0 auto;
	display: flex;
	// flex-direction: row;

	section {
		width: 50%;
		padding: 30px;
		background-color: $footer-color;

		address {
			font-family: 'PT Serif', sans-serif;
			font-style: normal;
			// background-color: rgba(0, 128, 68, 0.85);
			// padding: 20px 80px;
			padding: 18px 0;

			p {
				// line-height: 20px;
				font-size: 18px;
				// color: #b8c9c7;
				color: #ddd;
				margin-bottom: 10px;
				text-align: center;

				&.lawyer {
					font-weight: bold;
					// letter-spacing: 1px;
					font-size: 22px;
				}

				&.opening {
					display: none;
				}
			}

			article {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 20px auto;

				div {
					// width: 350px;
					text-align: center;
					margin: 30px auto;

					p {
						// text-align: left;
						margin-bottom: 7px;
					}

					p.address {
						font-size: 17px;
					}

					p.phone {
						// margin-bottom: 0;
					}

					a {
						// color: #241e1e;
						color: inherit;
						text-decoration: none;
						font-size: 17px;
						// line-height: 27px;
					}

					a:hover {
						color: #fff;
					}

					a::before {
						/*background-size: 100%;*/
						background-repeat: no-repeat;
						display: inline-block;
						width: 17px;
						height: 17px;
						content: "";
						margin-right: 15px;
						/*padding-bottom: 4px;*/
					}

					p.phone a::before {
						background-image: url("../img/icons/phone_light.svg");
					}

					p.mail a::before {
						background-image: url("../img/icons/mail_light.svg");
						/*background-size: 110%;*/
						position: relative;
						top: 3px;
					}
				}
			}
		}

		form {
			// margin: 0 auto;
			width: 100%;

			h4 {
				font-size: 22px;
				color: #ddd;
				margin-bottom: 5px;
				text-align: left;
			}

			hr {
				height: 1px;
				background: linear-gradient(to right, #fff, $footer-color);
				border: none;
				margin-bottom: 10px;
			}

			input, textarea {
				outline: none;
			}

			input[type=text] {
				width: 100%;
				height: 30px;
				padding: 15px;
				font-size: 14px;
				margin: 10px auto;
				font-family: 'Montserrat', sans-serif;
				background: transparent;
				border: 1px solid #ddd;
				border-radius: 2px;
				color: #eee;
			}

			textarea {
				width: 100%;
				height: 200px;
				padding: 15px;
				margin: 10px auto;
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				border: 1px solid #ddd;
				border-radius: 3px;
				resize: none;
				background: transparent;
				color: #eee;
			}

			input[type=submit] {
				width: 100%;
				height: 30px;
				border: 0;
				border-radius: 3px;
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				margin: 10px auto;
				border: 1px solid #ddd;
				background: transparent;
				color: #ddd;

				&:hover {
					color: rgba(0, 87, 49, 0.85);
					font-weight: bold;
					background: #ddd;
					cursor: pointer;
				}
			}

			#info {
				color: #f55;
				text-align: center;
				font-size: 14px;
			}
		}

		.rodo {
			display: block;
			text-align: center;
			width: 100%;
			padding-bottom: 5px;

			color: #bb1;
			font-size: 13px;
			// font-style: italic;
			font-family: 'Play', sans-serif;
			background: none;

			span {
				font-weight: bold;
			}

			&:hover {
				color: #cc2;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 750px) {
		section:first-child {
			padding-right: 0;
		}
	}

	// @media screen and (max-width: 670px) {
	// 	section:first-child {
	// 		padding-left: 10px;
	// 	}
	// 	form {
	// 		padding-left: 10px;
	// 	}
	// }

	@media screen and (max-width: 670px) {
		display: block;

		section {
			width: 100%;

			&:first-child {
				padding-left: 0;
			}

			address {
				padding: 0;

				article div {
					margin: 0 auto;

					&:first-child {
						margin-bottom: 20px;
					}
				}

				p.lawyer {
					font-size: 20px;
				}
			}

			form {
				margin: 0 auto;
			}

			&.contact-page__form {
				padding-top: 0;

				h4 {
					font-size: 20px;
				}
			}
		}
	}

	@media screen and (max-width: 350px) {
		section address article div p.address, section address article div p a {
			font-size: 15px;
		}

		section form input[type=text], section form textarea {
			font-size: 13px;
			padding: 8px;
		}
	}
}

section.contact-page__map {
	width: 100%;
	height: 500px;

	iframe {
		width: 100%;
		height: inherit;
	}

	@media screen and (max-width: 600px) {
		height: 300px;
	}
}

@keyframes spin {
    0%, 100% {
        transform: translate(0)
    }
    25% {
        transform: translate(160%)
    }
    50% {
        transform: translate(160%, 160%)
    }
    75% {
        transform: translate(0, 160%)
    }
}