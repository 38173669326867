

.contact-page-sub {
	background-image: url("../img/justice-2060093_1920.jpg");
	filter: sepia(0.5);
	// filter: grayscale(0.4);
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: row;

	section.contact-page__address {
		width: 100%;
		padding: 15px;
		background-color: $footer-color;

		address {
			font-family: 'PT Serif', sans-serif;
			font-style: normal;

			p {
				font-size: 14px;
				// color: #b8c9c7;
				color: #ddd;
				margin-bottom: 10px;
				text-align: center;

				&.lawyer {
					font-weight: bold;
					// letter-spacing: 1px;
					font-size: 18px;
				}
			}

			article {
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin: 5px auto;
				font-size: 14px;

				@media screen and (max-width: 600px) {
					display: block !important;
				}

				div {
					// width: 350px;
					text-align: center;
					margin: 0 auto;
					display: flex;
					flex-direction: column;

					&:first-child {
						@media screen and (max-width: 600px) {
							margin: 20px auto !important;
						}
					}

					p {
						margin-bottom: 7px;
					}

					p.address {
						font-size: 14px;
					}

					a {
						// color: #241e1e;
						color: inherit;
						text-decoration: none;
						// font-size: 17px;
						// line-height: 27px;

						@media screen and (max-width: 300px) {
							font-size: 13px !important;
						}
					}

					a:hover {
						color: #fff;
					}

					a::before {
						/*background-size: 100%;*/
						background-repeat: no-repeat;
						display: inline-block;
						width: 17px;
						height: 17px;
						content: "";
						margin-right: 15px;
						/*padding-bottom: 4px;*/
					}

					p.phone a::before {
						background-image: url("../../assets/icons/phone_light.svg");
					}

					p.mail a::before {
						background-image: url("../../assets/icons/mail_light.svg");
						/*background-size: 110%;*/
						position: relative;
						top: 3px;
					}
				}
			}
		}
	}
}

.contact-page-sub {
	article {
		@media screen and (max-width: 600px) {
			display: block !important;
		}

		div {
			display: block !important;

			&:first-child {
				@media screen and (max-width: 600px) {
					margin: 20px auto !important;
				}
			}

			a {
				@media screen and (max-width: 300px) {
					font-size: 13px !important;
				}
			}
		}
	}
}